<template>
  <div @click="emitElementClicked" :id="id" class="input-field-container">
    <div
      ref="container"
      :key="fieldUpdater"
      @click="focusInput"
      @dragover="handleDrag"
      :class="fieldClass"
      :style="fieldStyle"
      :id="id"
    >
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        style="display: block"
        v-if="iconLeft"
        :src="require(`@/assets/images/` + iconLeft)"
        alt=""
      />
      <div
        :class="{ required: fieldRequired }"
        style="display: flex; width: 100%; flex-direction: column"
      >
        <label v-if="fieldPlaceholder" ref="label" class="content">{{
          fieldPlaceholder
        }}</label>
        <input
          :id="'input-' + id"
          :type="fieldType"
          :disabled="fieldDisabled"
          :hidden="hidden"
          @focus="focusInputElement"
          @focusout="unfocusInput"
          ref="input"
          class="input-field content"
          v-model="internalValue"
          :autocomplete="autoComplete"
          :maxlength="maxCharsLength"
          @input="onInput"
        />
      </div>
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        v-if="iconRight"
        :src="require(`@/assets/images/` + iconRight)"
        alt=""
      />
    </div>
    <div
      v-if="fieldError"
      class="content error-message"
      style="margin: 12px 12px 0 0"
    >
      {{
        fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    modelValue: String,
    id: String,
    format: String,
    placeholder: String,
    required: Boolean,
    size: String,
    iconLeft: String,
    iconRight: String,
    errorMessage: String,
    error: Boolean,
    disabled: Boolean,
    valueChanged: Boolean,
    autoFocus: Boolean,
    passwordFields: Boolean,
    autoComplete: String,
    spacesRemoval: Boolean,
    maxCharsLength: Number,
    hidden: Boolean,
    checkForHiding: Boolean,
    type: String,
  },
  data() {
    return {
      internalValue: this.modelValue,
      fieldType: this.type,
      fieldPlaceholder: "",
      formattedValue: "",
      newValue: "",
      fieldRequired: false,
      fieldSize: "large",
      fieldClass: "form-field-large",
      fieldError: false,
      fieldErrorMessage: false,
      fieldDisabled: false,
      fieldHidden: false,
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "7px 11px",
        gap: "12px",
        background: "white",
        cursor: "text",
      },
      inputFieldTypes: ["number", "text", "password"],
      fieldUpdater: 0,
      fieldFocus: false,
    };
  },
  watch: {
    modelValue(newValue) {
      this.internalValue = newValue;
    },
    internalValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
    disabled(newDisabled) {
      this.fieldDisabled = newDisabled;
      this.disableField(newDisabled);
    },
    checkForHiding(newHidden) {
      this.fieldHidden = newHidden;
      if (this.fieldHidden) {
        this.hideField();
      } else {
        this.activeField();
      }
    },
    error() {
      this.checkError();
    },
    errorMessage(newErrorMessage) {
      this.fieldErrorMessage = newErrorMessage;
    },
  },
  mounted() {
    if (this.autoFocus) {
      this.focusInputElement();
    }
    this.initialize();
    this.checkError();
  },
  methods: {
    onInput(event) {
      let value = event.target.value;

      if (this.fieldType === "number") {
        value = this.formatNumberInput(value);

        const numericValue = Number(value);

        if (!isNaN(numericValue)) {
          this.internalValue = numericValue;
          this.$emit("update:modelValue", this.internalValue);
        } else {
          this.internalValue = "";
          this.$emit("update:modelValue", this.internalValue);
        }
      } else {
        this.internalValue = value;
        this.$emit("update:modelValue", this.internalValue);
      }
    },
    checkError() {
      this.fieldError = this.error;
      if (this.fieldError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        if (this.fieldFocus) {
          if (this.$refs.label) {
            this.$refs.label.style.color = "#FF1E24";
          }
        }
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        if (this.$refs.label) {
          this.$refs.label.style.color = "";
        }
        if (!this.validateInput(this.internalValue)) {
          if (this.$refs.label) {
            this.$refs.label.style.color = "#FF5C01";
          }
        }
      }
    },
    getFormattedPrice(price) {
      let parts = price.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    async initialize() {
      await this.mapProps();
    },
    formatNumberInput(value) {
      let outputValue = String(value);
      var regExp = /[^0-9.,]/g;
      if (outputValue.includes(",")) {
        outputValue = outputValue.split(",").join(".");
      }
      if (outputValue.split(".").length > 2 || regExp.test(outputValue)) {
        outputValue = outputValue.slice(0, -1);
      }
      return outputValue;
    },
    formatFieldInput(value) {
      let outputValue = String(value);
      var regExp = /\s/g;
      if (regExp.test(outputValue)) {
        outputValue = outputValue.replace(/\s/g, "");
      }
      return outputValue;
    },
    mapProps() {
      if (this.modelValue || this.modelValue === 0) {
        this.internalValue = this.modelValue;
      }
      if (this.type && this.type !== "number") {
        this.fieldType = this.type.toLowerCase();
      }
      if (this.modelValue || this.modelValue === 0) {
        this.activatePlaceholder();
      }
      if (!this.modelValue && this.modelValue !== 0) {
        if (this.inputFieldTypes.includes(this.fieldType)) {
          this.$nextTick(() => {
            if (this.$refs.input) {
              this.$refs.input.style.display = "hidden";
              this.$refs.input.style.height = "0px";
            }
          });
        }
        this.$nextTick(() => {
          if (this.$refs.label) {
            this.$refs.label.style.transition = "0.2s";
          }
        });
      }
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.required) {
        this.fieldRequired = true;
      }
      if (this.disabled) {
        this.disableField(true);
      } else {
        this.disableField(false);
      }
      this.fieldUpdater++;
      if (this.autoFocus) {
        this.focusInputElement();
      }
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    focusInput() {
      if (this.fieldDisabled) {
        return;
      }
      if (this.$refs.input) {
        this.$refs.input.style.display = "block";
        this.$refs.input.focus();
      }
    },
    handleDrag() {
      this.focusInputElement();
    },
    focusInputElement() {
      this.$emit("click");
      this.fieldFocus = true;
      this.$nextTick(() => {
        if (this.$refs.input) {
          this.$refs.input.style.transition = "0.2s";
          this.$refs.input.style.height = "24px";
        }
      });
      this.activatePlaceholder();
    },
    async unfocusInput() {
      if (!this.validateInput(this.internalValue)) {
        if (this.$refs.input) {
          this.$refs.input.style.display = "hidden";
          this.$refs.input.style.height = "0px";
        }
        this.deactivePlaceholder();
      }
      this.fieldFocus = false;
      if (this.$refs.label) {
        this.$nextTick(() => (this.$refs.label.style.color = "#75787A"));
      }
      this.$emit("blur");
    },
    activatePlaceholder() {
      this.$nextTick(() => {
        if (this.$refs.label) {
          if (
            this.$refs.label.classList.contains(
              "form-field-large-active-placeholder",
            )
          ) {
            return;
          }
          this.$refs.label.classList.add("form-field-large-active-placeholder");
        }
      });
      if (this.fieldFocus && !this.fieldError) {
        this.$nextTick(() => {
          if (this.$refs.label) {
            this.$refs.label.style.color = "#FF5C01";
          }
        });
      }
      if (this.fieldFocus && this.fieldError) {
        this.$nextTick(() => {
          if (this.$refs.label) {
            this.$refs.label.style.color = "#FF1E24";
          }
        });
      }

      this.$nextTick(() => {
        if (this.$refs.label) {
          this.$refs.label.classList.add("required-mark");
        }
      });
    },
    deactivePlaceholder() {
      console.trace("deactivate");
      if (this.$refs.label) {
        this.$refs.label.classList.remove(
          "form-field-large-active-placeholder",
        );
        this.$nextTick(() => (this.$refs.label.style.color = "#75787A"));
        this.$refs.label.classList.remove("required-mark");
        this.$nextTick(() => (this.$refs.label.style.transition = "0.2s"));
      }
    },
    disableField(isDisabled) {
      if (isDisabled) {
        this.fieldRequired = false;
        this.fieldDisabled = true;
        this.fieldStyle.background = "#F4F5F7";
        this.$nextTick(() => {
          if (this.$refs.label) {
            this.$refs.label.style.cursor = "default";
          }
        });
        this.$nextTick(() => (this.fieldStyle.cursor = ""));
      } else {
        this.fieldDisabled = false;
        this.fieldStyle.background = "white";
      }
    },
    hideField() {
      this.fieldRequired = false;
      this.fieldHidden = true;
      this.$nextTick(() => {
        if (this.$refs.container) {
          this.$refs.container.style.display = "none";
        }
      });
    },
    activeField() {
      this.fieldHidden = false;
      this.$nextTick(() => {
        if (this.$refs.container) {
          this.$refs.container.style.display = "flex";
        }
      });
    },
    emitElementClicked() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.input-field-container {
  width: 100%;
  position: relative;
}

.input-field {
  width: 100%;
  height: 24px;
  outline: none;
  cursor: text;
}

.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.form-field-large input {
  height: 24px;
}

.required label::after {
  position: absolute !important;
  line-height: unset !important;
}

.required-mark::after {
  font-size: 12px !important;
  line-height: unset !important;
  position: absolute !important;
}

.form-field-large-active-placeholder {
  height: 12px !important;
  line-height: 12px !important;
  font-size: 11px !important;
}

.error-message {
  color: #ff1e24 !important;
}

.icon-disabled {
  opacity: 0.5;
}

.dropdown-container {
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.04),
    0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
  z-index: 110;
  border-radius: 8px;
  position: absolute;
  max-height: 300px;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
}

.dropdown-item {
  padding: 12px;
  background: white;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f4f5f7;
}

input[type="date"]:after {
  content: "";
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 48px;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

.dropdown-medium {
  max-height: 256px !important;
}

.dropdown-small {
  max-height: 212px !important;
}

.bordert {
  border-top: 1px solid #e6e8ec;
}
</style>
